<template>
  <StepPage pageName="Preparar cotação" :pageNumber="1" />
</template>

<script>
import StepPage from '@/components/SpecialistSteps/StepPage';

export default {
  name: 'PrepareQuote',
  components: {
    StepPage
  }
};
</script>
